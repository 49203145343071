import { getPublicPhotoIds } from "../api/imageApi";
import { useState, useEffect } from "react";
import { baseURL } from "../constants";
import React from "react";

export default function Gallery() {
  const [photoLinks, setPhotoLinks] = useState([]);
  useEffect(() => {
    getPublicPhotoIds().then((photoIds) =>
      setPhotoLinks(photoIds.map((photoId) => `${baseURL}/photo/${photoId}`))
    );
  }, []);
  return (
      <div  style={{ marginLeft: "20%", marginRight: "20%", marginTop: "3%" }}>
        {photoLinks.map((image, index) => (
          <img
            src={`${image}`}
            key={index}
            loading="lazy"
            alt="…"
            width="300em" height="300em"
          ></img>
        ))}
      </div>
  );
}
