import { CardMedia, IconButton, Box } from "@mui/material";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { uploadPhoto } from "../api/imageApi";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import copy from "copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { baseURL } from "../constants";

export default function UploadPhotoModal(props) {
  const [file, setFile] = useState("");
  const [linkToFile, setLinkToFile] = useState("");
  const [isUploadDisabled, setIsUploadDisabled] = useState(true);
  const [photoUploadedUrl, setPhotoUploadedUrl] = useState("");
  const [uploadResultTitle, setUploadResultTitle] = useState("");

  const token = window.location.href.split("/").pop();

  const resetModal = () => {
    props.SetModalStatus(false);
    setIsUploadDisabled(true);
    setFile("");
    setLinkToFile("");
    setPhotoUploadedUrl("");
  };

  const handleChoosePhoto = (e) => {
    let linkToFile = URL.createObjectURL(e.target.files[0]);
    setFile(e.target.files[0]);
    setLinkToFile(linkToFile);
    setIsUploadDisabled(false);
  };
  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("photo", file, file);
    uploadPhoto(formData, token).then((res) => {
      setPhotoUploadedUrl(`${baseURL}/photo/${res.data.uploadedPhotoGuid}`);
      setUploadResultTitle(res.data.resultTitle);
    });
  };

  const Input = styled("input")({
    display: "none",
  });

  useEffect(() => {}, []);
  return (
    <Dialog open={props.isVisible} onClose={() => resetModal()}>
      {photoUploadedUrl.length === 0 && (
        <React.Fragment>
          <DialogTitle>Новая фотография</DialogTitle>
          <DialogContent sx={{ minHeight: "20vh", minWidth: "25vw" }}>
            <DialogContentText>
              <Paper>
                {linkToFile.trim().length > 0 && (
                  <CardMedia component="img" src={linkToFile} alt="" />
                )}
              </Paper>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <label htmlFor="contained-button-file">
              <Input
                id="contained-button-file"
                type="file"
                onChange={handleChoosePhoto}
              />
              <Button component="span">Выбрать Фотографию</Button>
            </label>
            <Button onClick={handleUpload} disabled={isUploadDisabled}>
              Загрузить
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
      {photoUploadedUrl.length > 0 && (
        <React.Fragment>
          <Box sx={{ display: "flex", flexDirection: "row", minWidth: "25vw" }}>
            <IconButton
              color="primary"
              onClick={() => copy(photoUploadedUrl)}
              style={{ marginRight: "3%", marginLeft: "auto" }}
            >
              <ContentCopyIcon />
            </IconButton>
            <DialogTitle>{uploadResultTitle}</DialogTitle>
          </Box>
          <div
            style={{
              display: "flexbox",
              marginBottom: "2%",
              paddingLeft: "5%",
              minWidth: "30vw",
            }}
          >
            {photoUploadedUrl}
          </div>
        </React.Fragment>
      )}
    </Dialog>
  );
}
