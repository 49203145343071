import "./App.css";
import AppTopBar from "./AppTopBar";
import PublicPhotosComponent from "./PublicPhotosComponent/PublicPhotosComponent";
import { useState } from "react";
import StatsComponent from "./StatsComponent/StatsComponent";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from "react";
import UploadPhotoModal from "./UploadPhotoModal/UploadPhotoModal";

function App() {
  const [showUploadModal, OnUploadClick] = useState(false);
  return (
    <Router>
      <React.Fragment>
        <AppTopBar OnUploadClick={() => OnUploadClick(!showUploadModal)}>
          <Routes>
            <Route path="/" element={<PublicPhotosComponent />} />
            <Route path="/admin/:token" element={<StatsComponent />} />
          </Routes>
        </AppTopBar>
        <UploadPhotoModal
          isVisible={showUploadModal}
          SetModalStatus={(val) => OnUploadClick(val)}
          isPublicPage={true}
        />
      </React.Fragment>
    </Router>
  );
}

export default App;
