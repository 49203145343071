import axios from "axios";

const api = axios.create();

// interface IUploadPhotoData {
//   uploadedPhotoGuid: string;
//   resultTitle: string;
// }

export const getPublicPhotoIds = async () => {
  var res = await api.get("photo/refs");
  return res.data;
};

export const uploadPhoto = async (formData, token) => {
  return await api.post(
    `photo/upload${token ? `?token=${token}` : ''}`,
    formData
  );
};
