import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useParams } from "react-router-dom";
import React from "react";

export default function StatsComponent() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableContent, setTableContent] = useState([{}]);
  const [tableHeaders, setTableHeaders] = useState({});
  const token = useParams().token;

  useEffect(() => {
    uploadAdminData().then((adminData) => {
      setTableContent(adminData.data.data);
      setTableHeaders(adminData.data.tableHeaders);
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const uploadAdminData = async () => {
    return await axios.get(
      `getall?token=${token}`
    );
  };

  var columns = [
    {
      id: Object.keys(tableHeaders)[0],
      label: Object.values(tableHeaders)[0],
      minWidth: 170,
    },
    {
      id: Object.keys(tableHeaders)[1],
      label: Object.values(tableHeaders)[1],
      minWidth: 100,
    },
    {
      id: Object.keys(tableHeaders)[2],
      label: Object.values(tableHeaders)[2],
      minWidth: 170,
      align: "right",
    },
    {
      id: Object.keys(tableHeaders)[3],
      label: Object.values(tableHeaders)[3],
      minWidth: 170,
      align: "right",
    },
  ];

  return (
    <React.Fragment>
      <Paper
        sx={{
          marginTop: "6vh",
          width: "100vw",
          height: "94vh",
          overflow: "hidden",
        }}
      >
        <TableContainer sx={{ maxHeight: "90%" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableContent
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, _index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={_index}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={tableContent.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </React.Fragment>
  );
}
