import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import UploadIcon from "@mui/icons-material/Upload";
import HomeIcon from "@mui/icons-material/Home";
import Tooltip from "@mui/material/Tooltip";

export default function AppTopBar(props) {
  return (
    <Box sx={{ flexGrow: 0.005 }}>
      <AppBar position="fixed">
        <Toolbar variant="dense">
          <Tooltip title="Галлерея">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => {
                window.location.replace("/");
              }}
            >
              <HomeIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Загрузить фотографию">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={props.OnUploadClick}
            >
              <UploadIcon />
            </IconButton>
          </Tooltip>
          <Typography variant="h6" color="inherit" component="div">
            Фотографии
          </Typography>
        </Toolbar>
      </AppBar>
      {props.children}
    </Box>
  );
}
